import Swal from 'sweetalert2';
import $ from "jquery";
import IMask from 'imask';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic/build/ckeditor';



window.onLoadApp = function(){

    const btnMenuToggle = document.getElementById("menu-toggle");
    const SIDEBAR = document.getElementById("sidebar");

    btnMenuToggle.addEventListener('click', () => {
        SIDEBAR.classList.toggle('toggle');
    });
  }
try {
    window.$ = window.jQuery = $;
    window.Swal = Swal;
    /** CKEditor 5 */
    window.ClassicEditor = ClassicEditor;

    var ready = (callback) => {
        if (document.readyState != "loading") callback();
        else document.addEventListener("DOMContentLoaded", callback);
      }
      ready(() => {
        var element = document.getElementById('customerPhone');
        if( element ){
            var maskOptions = {
                mask: '+{38} (000) 000 0000'
              };
              var mask = IMask(element, maskOptions);
            
        }

        function MinHeightPlugin(editor) {
            this.editor = editor;
        }
          
        MinHeightPlugin.prototype.init = function() {
            this.editor.ui.view.editable.extendTemplate({
              attributes: {
                style: {
                  minHeight: '200px'
                }
              }
            });
          };
      
        document.querySelectorAll('.editor').forEach(function (val) {
    
            //ClassicEditor.builtinPlugins.push(MinHeightPlugin);
    
            ClassicEditor
                .create(val, {
                    //extraPlugins: [ SimpleUploadAdapterPlugin ],
                })
                .then(function (editor) {
                })
                .catch(error => {
                    console.log(error);
                });
        });
    

      });
    
      


    
 }
 catch (e) {
    // инструкции для обработки ошибок
    console.log(e); // передать объект исключения обработчику ошибок
 }
 

window.deleteConfirm = function (e) {
    var ele = e.target;
    e.preventDefault();
    var icon = "error";
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(function (e) {
        if (e.value === true) {
            const csrf_token = $('meta[name="csrf-token"]').attr('content');

            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });

            $.ajax({
                url: ele.href,
                type: 'POST',
                data: {
                    '_method' : 'DELETE',
                    '_token' : csrf_token,
                },
                dataType: 'json', 
                contentType:'application/json', 
                success: function (data) {
                    var title = "Ooops...";
                    if(data.success) {
                        icon = "success";
                        title = "Hooray!!!";
                    }
                    Swal.fire({
                        type : 'success',
                        title: title,
                        text : data.message,
                        icon: icon,
                        timer : '2500'
                    }).then(function() {
                        window.location.reload();
                    });
                    //$('.data-table').DataTable().ajax.reload(null, false);  
                },
                error : function(data){
                    Swal.fire({
                        title: 'Opps...',
                        text : 'Something wrong!!!',
                        type : 'error',
                        icon: icon,
                        timer : '1500'
                    })
                }
            });
        } else {
            e.dismiss;
        }
      });
}


window.loadFile = function(event) {
    var reader = new FileReader();
    reader.onload = function(){
      $('#output').hide();
      $('#output').attr("src", reader.result);
      $('#output').fadeIn(650);
    };
    reader.readAsDataURL(event.target.files[0]);
    
  
  };

  window.reloadDataTable = function(){
    $('.data-table').DataTable().ajax.reload(null, false); 
}
