<template>
    <div class="group relative">
        <div class="bg-white w-10 h-10 flex items-center justify-center duration-150 relative"
            :class="{ 'text-orange-500': messages.length > 0 }">
            <IconMessages stroke="{2}" :size="20" />
            <div v-if="messages.length > 0"
                class="text-xs absolute w-4 h-4 bg-orange-500 top-0 right-0 rounded-full flex items-center justify-center text-white">
                <span>{{ messages.length }}</span>
                <div class="bg-white absolute top-8 rounded-lg right-0 shadow">
                    <ul class="min-w-64 divide-y">
                        <li v-for="message in messages" :key="message" class="hover:bg-slate-100">
                            <div class="flex  items-center gap-3 px-3 py-2">
                                <img :src="message.user.avatar" :alt="message.user.full_name"
                                    class="w-6 h-6 rounded-full" />
                                <div class="w-full overflow-x-auto text-gray-600">
                                    <a :href="`/taskboards/${message.model}`">
                                        <p class="text-xs text-gray-500 font-semibold">{{ message.user.full_name }}</p>
                                        <p>{{ message.message }}</p>
                                    </a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import { IconMessages } from "@tabler/icons-vue";

export default {
    name: "Notification",
    components: {
        IconMessages,
    },
    props: {
        user: {
            type: Object,
            default: () => { },
        },
        models: {
            type: Array,
        },
    },
    setup(props) {
        var data = { soundurl: "/storage/media/livechat.mp3" };

        const messages = ref([]);

        for (let i = 0; i < props.models.length; i++) {
            Echo.channel(`chat_${props.models[i]}`).listen(
                "MessageSent",
                (e) => {
                    console.log(e);
                    if (props.user.id !== e.message.user_id) {
                        var audio = new Audio(data.soundurl);
                        audio.play();

                        messages.value.push({
                            image: e.message.image,
                            model: e.message.model_id,
                            message: e.message.message,
                            user: e.user,
                        });
                    }
                }
            );
        }

        return {
            messages,
        };
    },
};
</script>
