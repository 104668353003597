<script>
import useFileManager from "../composables/file_manager";
import FileManagerIconDocument from "../components/FileManagerIconDocument.vue";
import { onMounted, ref, computed, reactive } from "vue";
import * as TablerIcons from "@tabler/icons-vue";
import Dropdown from "../components/Dropdown.vue";
import Modal from "../components/Modal.vue";
import SecondaryButton from "../components/SecondaryButton.vue";
import TextInput from "../components/TextInput.vue";
import InputLabel from "../components/InputLabel.vue";
import DangerButton from "../components/DangerButton.vue";
import PrimaryButton from "../components/PrimaryButton.vue";

export default {
    components: {
        ...TablerIcons,
        PrimaryButton,
        DangerButton,
        InputLabel,
        TextInput,
        SecondaryButton,
        Modal,
        Dropdown,
        FileManagerIconDocument,
    },
    props: { current_href: Object },
    setup(props) {
        const {
            errors,
            files,
            directories,
            loading,
            getResponce,
            storeFolder,
            uploadFiles,
            destroy,
            download,
        } = useFileManager();
        const showModalNewNameFolder = ref(false);
        const confirmingDeletionFolder = ref(false);
        const confirmingDeletionFile = ref(false);

        const path = computed(() => {
            const url = new URL(props.current_href[0].url).pathname.split("/");
            const newPath = url.slice(2).join("/");
            return newPath;
        });

        const previous_url = computed(() => {
            let base = 'http://' + window.location.host;
            const segments = (path.value || "/").split("/");
            segments.pop();
            const join = segments.join("/");
            let url = new URL(`/filemanager/${join}`, base);
            return url;
        });

        const form = reactive({
            type: "",
            name: "",
            path: path.value || "",
        });

        const title = computed(() => {
            let cleanedPath = path.value
                ? path.value.replace(/^\//, "").replace(/\//g, "")
                : ""; // Видаляє перший символ '/' та всі інші символи '/'
            return cleanedPath || "FileManager"; // Повертає очищений шлях або "FileManager" за замовчуванням
        });

        onMounted(async () => getResponce(form));

        function reloadFManager() {
            getResponce(form);
        }

        function generate(folder) {
            var pathValue = path.value + "/" + folder;
            var resultPath = pathValue.replace(/\/{2,}/g, "/"); // видаляє подвійні слеші
            return resultPath.startsWith("/")
                ? resultPath.slice(1)
                : resultPath;
        }

        function newFolder() {
            storeFolder(form);
            showModalNewNameFolder.value = false;
            form.value = {}
        }

        function confirmDeletionFolder(folder) {
            form.name = folder;
            confirmingDeletionFolder.value = true;
        }

        function confirmDeletion(file) {
            form.name = file;
            confirmingDeletionFile.value = true;
        }

        function deleteFolder() {
            form.type = "folder";
            destroy(form);
            confirmingDeletionFolder.value = false;
            form.value = {}
        }

        function deleteFile() {
            form.type = "file";
            destroy(form);
            confirmingDeletionFile.value = false;
            form.value = {}
        }

        function chooseFiles() {
            document.getElementById("inputFile").click();
        }

        function handleFileUpload(event) {
            event.preventDefault();

            let formData = new FormData();
            const files = event.target.files;
           
            for (var i = 0; i < files.length; i++) {
                console.log(files[i]);
                formData.append("files[]", files[i]); // Using the correct key for multiple files
            }

            formData.append("path", this.form.path);
            uploadFiles(formData, form);
        }

        function downloadFolder(name) {
            form.type = "folder";
            form.name = name;
            download(form);
        }

        function downloadFile(name) {
            form.name = name;
            form.type = "file";
            form.name = name;
            download(form);
        }

        function closeModal() {
            form.value = {};
            showModalNewNameFolder.value = false;
            confirmingDeletionFolder.value = false;
            confirmingDeletionFile.value = false;
        }

        return {
            errors,
            files,
            directories,
            loading,
            showModalNewNameFolder,
            confirmingDeletionFolder,
            confirmingDeletionFile,
            path,
            previous_url,
            form,
            title,
            reloadFManager,
            generate,
            newFolder,
            confirmDeletionFolder,
            confirmDeletion,
            deleteFolder,
            deleteFile,
            chooseFiles,
            handleFileUpload,
            downloadFolder,
            downloadFile,
            closeModal,
        };
    },
};
</script>

<template>
    <div>
        <div v-if="errors.length" class="py-4 text-xs text-red-400">
            <span>{{ errors }}</span>
        </div>
        <div>
            <div class="overflow-x-auto min-h-screen bg-white border rounded-md border-gray-200 dark:border-slate-700">

                <form enctype="multipart/form-data">
                    <input type="file" id="inputFile" hidden v-on:change="handleFileUpload($event)" multiple />
                </form>

                <section class="bg-slate-50 p-2 border-b">
                    <div class="flex gap-x-2 items-center">
                        <button class="rounded-full bg-white shadow-sm p-2 active:shadow-none hover:text-orange-500"
                            @click="reloadFManager">
                            <IconRefresh stroke="{2}" size="18" :class="{ 'animate-spin': loading }" />
                        </button>
                        <button class="rounded-full bg-white shadow-sm p-2 active:shadow-none hover:text-orange-500"
                            @click="
                                showModalNewNameFolder = !showModalNewNameFolder
                                ">
                            <IconFolderPlus stroke="{2}" size="16" />
                        </button>
                        <button
                            class="rounded-full bg-white shadow-sm p-2 active:shadow-none flex items-center gap-x-2 text-xs hover:text-orange-500"
                            @click="chooseFiles">
                            <IconCloudUpload stroke="{2}" size="16" />
                            <span>Upload file</span>
                        </button>
                    </div>
                </section>

                <section>
                    <table class="text-sm text-gray-600 w-full">
                        <thead class="border-b uppercase text-xs">
                            <tr>
                                <th class="p-2 px-4 text-left">Name</th>
                                <th class="p-2 text-left">Size</th>
                                <th class="p-2 text-left">Last modified</th>
                                <th class="p-2"></th>
                            </tr>
                        </thead>
                        <!-- <tbody v-if="path != ''">
                            <tr class="relative border-b hover:bg-slate-100">
                                <td colspan="4" class="px-4 py-2">
                                    <a :href="previous_url">
                                        <IconArrowBackUp stroke="{2}" size="24" />
                                    </a>
                                </td>
                            </tr>
                        </tbody> -->
                        <!-- Directories sections -->
                        <tbody v-if="directories">
                            <tr v-for="file in directories" :key="file" class="relative border-b hover:bg-slate-100">
                                <td class="p-2">
                                    <a :href="`/filemanager/${generate(
                                        file.basename
                                    )}`">


                                        <div class="flex items-center gap-x-2 px-2">
                                            <IconFolder stroke="{1}" :size="24" class="text-green-600" />
                                            <span>{{ file.basename }}</span>
                                        </div>
                                    </a>
                                </td>
                                <td class="p-2">...</td>
                                <td class="p-2 text-xs">
                                    {{ file.last_modified }}
                                </td>
                                <td class="p-2">
                                    <div class="relative">
                                        <Dropdown align="right" width="48">
                                            <template #trigger>
                                                <button>
                                                    <IconDotsVertical stroke="{2}" :size="24" />
                                                </button>
                                            </template>
                                            <template #content>
                                                <button @click="
                                                    confirmDeletionFolder(
                                                        file.basename
                                                    )
                                                    " class="flex items-center gap-x-2 p-2">
                                                    <IconTrash stroke="{2}" size="16" />
                                                    Delete
                                                </button>
                                                <button @click="
                                                    downloadFolder(
                                                        file.basename
                                                    )
                                                    " class="flex items-center gap-x-2 p-2">
                                                    <IconDownload stroke="{2}" size="16" />
                                                    Download
                                                </button>
                                            </template>
                                        </Dropdown>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <!-- /Directories sections-->
                        <!-- Files sections -->
                        <tbody v-if="files">
                            <tr v-for="file in files" :key="file" class="border-b hover:bg-slate-100">
                                <td class="p-2">
                                    <div class="flex items-center gap-x-2 px-2">
                                        <FileManagerIconDocument :size="24" :extension="file.extension" :file="file" />
                                        <span>{{ file.basename }}</span>
                                    </div>
                                </td>
                                <td class="p-2 text-xs">{{ file.size }}</td>
                                <td class="p-2 text-xs">
                                    {{ file.last_modified }}
                                </td>
                                <td class="p-2">
                                    <div class="relative">
                                        <Dropdown align="right" width="48">
                                            <template #trigger>
                                                <button>
                                                    <IconDotsVertical stroke="{2}" :size="24" />
                                                </button>
                                            </template>
                                            <template #content>
                                                <button @click="
                                                    confirmDeletion(
                                                        file.basename
                                                    )
                                                    " class="flex items-center gap-x-2 p-2">
                                                    <IconTrash stroke="{2}" size="16" />
                                                    Delete
                                                </button>
                                                <button @click="
                                                    downloadFile(
                                                        file.basename
                                                    )
                                                    " class="flex items-center gap-x-2 p-2">
                                                    <IconDownload stroke="{2}" size="16" />
                                                    Download
                                                </button>
                                            </template>
                                        </Dropdown>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <!-- /Files sections-->
                        <!-- Empty Data sections -->
                        <tbody v-if="!directories & !files">
                            <tr>
                                <td class="p-2" colspan="3">Data empty</td>
                            </tr>
                        </tbody>
                        <!-- /Empty Data sections -->
                    </table>
                </section>
            </div>
        </div>
        <!-- modals -->
        <section>
            <!-- naming new folder -->
            <Modal :show="showModalNewNameFolder" @close="closeModal">
                <div class="p-4 md:p-8">
                    <div class="flex flex-col space-y-4">
                        <div>
                            <InputLabel for="name" :value="`Name folder in '${path + '/...'}'`" />
                            <TextInput id="name" ref="nameFolder" v-model="form.name" type="text"
                                class="mt-1 block w-full" placeholder="New folder" @keyup.enter="newFolder" />

                        </div>
                        <div class="flex items-center pt-4 gap-x-2 justify-end">
                            <SecondaryButton @click="closeModal">
                                Cancel
                            </SecondaryButton>
                            <PrimaryButton @click="newFolder">Create</PrimaryButton>
                        </div>
                    </div>
                </div>
            </Modal>

            <!-- Delete Form -->
            <Modal :show="confirmingDeletionFolder" @close="closeModal">
                <div class="p-4 md:p-8 bg-slate-100 flex flex-col text-gray-500">
                    <h2 class="text-lg font-medium text-gray-900 text-center">
                        Are you sure you want to delete your folder
                        <span class="font-semibold">`{{ form.name }}`</span>?
                    </h2>
                    <div class="flex justify-center pt-4">
                        <DangerButton @click="deleteFolder">Delete</DangerButton>
                    </div>
                </div>
            </Modal>

            <!-- Delete File Form -->
            <Modal :show="confirmingDeletionFile" @close="closeModal">
                <div class="p-4 md:p-8 bg-slate-100 flex flex-col text-gray-500">
                    <h2 class="text-lg font-medium text-gray-900 text-center">
                        Are you sure you want to delete your file
                        <span class="font-semibold">`{{ form.name }}`</span>?
                    </h2>
                    <div class="flex justify-center pt-4">
                        <DangerButton @click="deleteFile">Delete</DangerButton>
                    </div>
                </div>
            </Modal>
        </section>
    </div>
</template>
