require("./bootstrap");
require("./main");
import { createApp } from "vue";

import Chat from "./components/Chat.vue"
import FileManager from "./components/FileManager.vue"
import Notification from "./components/Notification.vue"

const app = createApp({});

app.component('chat',Chat)
app.component('filemanager', FileManager);


app.mount("#app");


const notification = createApp({});
notification.component('notification', Notification);
notification.mount("#notification");