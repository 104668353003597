import { ref } from "vue";
import axios from "axios";

export default function useFileManager() {
    const loading = ref(false);
    const files = ref([]);
    const directories = ref([]);
    const errors = ref([]);

    const handleErrors = (e) => {
        if (!(e instanceof Error)) {
            e = new Error(e);
        }
        errors.value = e.message;
        console.log(errors);
    };

    const getResponce = async (form) => {
        try {
            loading.value = true;
            files.value = [];
            directories.value = [];
            const response = await axios.get('/api/files', { params: form });
            if (response.data.status === false) {
                errors.value = response.data.message;
            } else {
               
                files.value = response.data.files || [];
                directories.value = response.data.directories || [];
            }
        } catch (e) {
            handleErrors(e);
        } finally {
            loading.value = false;
        }
    };

    const storeFolder = async (form) => {
        try {
            loading.value = true;
            await axios.post('/api/files', form);
            await getResponce(form);
        } catch (e) {
            handleErrors(e);
        } finally {
            loading.value = false;
        }
    };

    const uploadFiles = async (formData, form) => {
        try {
            loading.value = true;
            await axios.post('/api/files/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => console.log(response));
            await getResponce(form);
        } catch (e) {
            handleErrors(e);
        } finally {
            loading.value = false;
        }
    };

    const destroy = async (form) => {
        try {
            loading.value = true;
            await axios.get(`/api/files/${form.name}/destroy`, { params: { path: form.path, type: form.type } });
            await getResponce(form);
        } catch (e) {
            handleErrors(e);
        } finally {
            loading.value = false;
        }
    };

    const download = async (form) => {
        try {
            loading.value = true;
            const response = await axios.post('/api/files/download', form, { responseType: 'blob' });
            const href = window.URL.createObjectURL(response.data);
            const anchorElement = document.createElement('a');
            anchorElement.href = href;
            anchorElement.download = form.name;
            document.body.appendChild(anchorElement);
            anchorElement.click();
            document.body.removeChild(anchorElement);
            window.URL.revokeObjectURL(href);
        } catch (e) {
            handleErrors(e);
        } finally {
            loading.value = false;
        }
    };
    return {
        loading,
        files,
        directories,
        errors,
        getResponce,
        storeFolder,
        uploadFiles,
        destroy,
        download,
    };
}
