<script setup>
import { onMounted, ref, defineModel } from 'vue';

const model = defineModel({
    type: String,
    required: true,
});

const input = ref(null);

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

defineExpose({ focus: () => input.value.focus() });
</script>

<template>
    <input
        class="p-2 border field:bg-orange-400 border-gray-200 dark:border-slate-700 dark:bg-slate-900 dark:text-slate-300 focus:border-violet-500 focus:ring-violet-500 rounded-md shadow-sm"
        v-model="model"
        ref="input"
    />
</template>
