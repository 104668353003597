<template>
    <div v-if="extensions.includes(file.extension)" class="w-12 h-12 bg-no-repeat bg-center bg-contain" :style="{ backgroundImage: `url('/storage/${file.dirname}')` }" />
    <component v-else :is="iconComponent" stroke="1" :size="size" class="text-orange-500" />
</template>

<script setup>
import { ref, computed } from "vue";
import {
    IconFileTypeDocx,
    IconFileTypePng,
    IconFileTypeXls,
    IconPhoto,
    IconFileZip,
    IconFile,
    IconFileTypePdf
} from "@tabler/icons-vue";

const props = defineProps({
    extension: {
        type: String,
        default: "zip",
    },
    size: Number,
    file: Object
});

const extensions = ref(["png", "jpg", "webp"])

const iconComponent = computed(() => {
    switch (props.extension) {
        case "docx":
            return IconFileTypeDocx;
        case "png":
            return IconFileTypePng;
        case "xlsx":
            return IconFileTypeXls;
        case "rar":
            return IconFileZip;
        case "zip":
            return IconFileZip;
        case "webp":
            return IconPhoto; jpg
        case "jpg":
            return IconPhoto;
        case "pdf":
            return IconFileTypePdf;
        default:
            return IconFile; // or any default icon
    }
});
</script>
